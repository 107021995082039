import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'

export const Row = (props: any) => <Flex {...props} />

export const Column = (props: any) => <Box {...props} />

export const CenteredRow = (props: any) => (
  <Flex alignItems="center" {...props} />
)
