import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Theme, { Mq } from '../themes/default'

import { IContentfulLink } from '../interfaces'

const partiallyActive = (className: string) => ({
  isPartiallyCurrent,
}: {
  isPartiallyCurrent: boolean
}) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
})

const BaseLinkStyles = `
  color: inherit;
  text-decoration: none;
`

const BaseButtonStyles = `
  text-decoration: none;
  font-family: ${Theme.fonts.secondary};
  font-weight: bold;
  font-size: 0.9375rem;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.5px;
  display: inline-block;
  box-shadow: 0px 0px 0px 10px ${rgba(Theme.colors.primary, 0.2)};
  background: ${Theme.colors.primary};
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  color: ${Theme.colors.white};
  padding: 1rem 1.6rem;
  min-width: 160px;
  transition: ${Theme.basicTransition};

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 0px 10px
      ${rgba(Theme.colors.primary, 0.3)};
    color: ${Theme.colors.darkBlue};
  }

  ${Mq.md} {
    font-size: 1.0625rem;
    padding: 1.1rem 1.8rem;
    min-width: 200px;
  }
`

export const UnstyledLink = styled.a`
  ${BaseLinkStyles}

  &.callout {
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    font-size: 0.9375rem;
  }
`

export const ButtonLink = styled.a`
  ${BaseButtonStyles}
`

export const Button = styled.button`
  ${BaseButtonStyles}

  &:disabled {
    opacity: 0.6;
  }
`

export const HeaderButton = styled(Button)`
  ${BaseButtonStyles}
  box-shadow: none;
`

export const ButtonGatsbyLink = styled(GatsbyLink)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
`

export const UnstyledGatsbyLink = styled(GatsbyLink)`
  ${BaseLinkStyles}
`

export const InlineGatsbyLink = styled(GatsbyLink)``

const RawNavLink = ({ className = '', ...rest }) => (
  <GatsbyLink getProps={partiallyActive(className)} {...rest} />
)

const NavItem = styled(RawNavLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  position: relative;
  transition: ${props => props.theme.basicTransition};
  font-size: 0.9375rem;

  &.callout {
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  &.active {
    color: ${props => props.theme.colors.primary};

    &:before {
      content: '';
      display: block;
      height: 6px;
      width: 100%;
      background: ${props => props.theme.colors.primary};
      position: absolute;
      top: -25px;
    }
  }

  ${Mq.md} {
    color: ${props => props.theme.colors.darkBlue};
  }
`

export const NavLink = ({
  link,
  callout,
}: {
  link: IContentfulLink
  callout: boolean
}) => {
  if (link.path) {
    return (
      <NavItem to={link.path} className={callout ? 'callout' : ''}>
        {link.title}
      </NavItem>
    )
  }

  return (
    <UnstyledLink
      href={link.externalLink}
      target="_blank"
      rel="noopener noreferrer"
      className={callout ? 'callout' : ''}
    >
      {link.title}
    </UnstyledLink>
  )
}

export const Link = ({ link }: { link: IContentfulLink }) => {
  if (link.path) {
    return <UnstyledGatsbyLink to={link.path}>{link.title}</UnstyledGatsbyLink>
  }

  return (
    <UnstyledLink
      href={link.externalLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.title}
    </UnstyledLink>
  )
}

export const LinkButton = ({ link }: { link: IContentfulLink }) => {
  if (link.path) {
    return <ButtonGatsbyLink to={link.path}>{link.title}</ButtonGatsbyLink>
  }

  return (
    <ButtonLink
      href={link.externalLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.title}
    </ButtonLink>
  )
}

export const query = graphql`
  fragment Link on ContentfulLink {
    id
    title
    path
    externalLink
  }
`
