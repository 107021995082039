import React from 'react'
import styled from '@emotion/styled'
import Theme from '../themes/default'
import { Mq } from '../themes/default'
import { rgba } from 'polished'

export const Paragraph = styled.p`
  color: ${rgba(Theme.colors.darkBlue, 0.7)};
  font-size: ${props => (props.largeText ? '1.0625rem' : '0.9375rem')};
  line-height: ${props => (props.largeText ? '1.6875rem' : '1.5625rem')};

  ${Mq.md} {
    font-size: ${props => (props.largeText ? '1.1875rem' : props.smallText ? '0.9375rem' :'1.0625rem')};
    line-height: ${props => (props.largeText ? '1.9375rem' : props.smallText ? '1.5625rem' : '1.6875rem')};
  }
`
export const Span = styled.span``

export const SmallTitle = styled(Span)`
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8125rem;
  margin-bottom: 0.5rem;
`
