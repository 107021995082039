import React from 'react'
import styled from '@emotion/styled'
import { Mq } from '../themes/default'
import { rgba } from 'polished'

export const H1 = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 1rem;

  ${Mq.xs} {
    font-size: 2rem;
  }

  ${Mq.md} {
    font-size: 4.375rem;
    margin-bottom: 1.5rem;
  }
`

export const H2 = styled.h2`
  font-size: 1.8rem;

  ${Mq.md} {
    font-size: 2.625rem;
  }
`

export const H3 = styled.h3`
  font-size: 1.5rem;

  ${Mq.md} {
    font-size: 2rem;
  }
`

export const H4 = styled.h4`
  font-size: 1.3rem;

  ${Mq.md} {
    font-size: 1.5rem;
  }
`

export const H5 = styled.h5``

export const H6 = styled.h6`
  &.subtitle {
    font-size: 0.8rem;
    color: ${rgba('#02314b', 0.5)};
    text-transform: uppercase;
  }
`
