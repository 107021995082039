/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import 'minireset.css/minireset.css'
import 'modern-normalize/modern-normalize.css'
import config from 'react-reveal/globals'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppContextProvider } from '../app-context'
import Theme from '../themes/default'
import { Mq } from '../themes/default'
import Header from './header'
import Footer from './footer'

config({ ssrFadeout: true })

const GlobalStyles = css`
  * {
    outline-color: ${Theme.colors.primary};
  }

  ::selection {
    background: ${rgba(Theme.colors.primary, 0.4)};
  }

  html,
  body,
  .app,
  .app > div[role='group'] {
    height: auto;
    font-family: ${Theme.fonts.secondary};
    color: ${Theme.colors.darkBlue};
  }

  // Gatsby's injected HTML,
  // not sure where to override this
  .app > div[role='group'] {
    position: relative;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${Theme.fonts.primary};
    font-weight: bold;
    color: ${Theme.colors.darkBlue};
    margin: 0;
  }

  p,
  a,
  span,
  li {
    font-family: ${Theme.fonts.secondary};
  }

  @keyframes progress {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .current-progress {
    animation: progress 8s linear;
  }

  .social-icon svg {
    position: relative;
    top: 2px;
    height: 1.5rem;
    transition: color 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      color: ${Theme.colors.primary};
    }
  }

  .slick-slide {
    height: auto;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

const MainContainer = styled.main`
  height: 100%;
  padding-top: 0;
`

const Main = styled.div``

const BorderStyles = `
  width: 1px;
  height: 100%;
  background: ${rgba(Theme.colors.darkBlue, 0.1)};
  position: absolute;
  top: 0;
  display: none;

  ${Mq.md} {
    display: block;
  }
`

const BorderLeft = styled.div`
  ${BorderStyles};
  left: 5%;
  z-index: 2;

  ${Mq.xxl} {
    left: 15%;
  }
`

const BorderRight = styled.div`
  ${BorderStyles};
  right: 5%;

  ${Mq.xxl} {
    right: 15%;
  }
`

const Layout = ({
  children,
  altBg,
  hideBorders,
  hideNavItems = false,
}: {
  children: any
  altBg?: boolean
  hideBorders?: boolean
  hideNavItems?: boolean
}) => {
  useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  )

  return (
    <ThemeProvider theme={Theme}>
      <AppContextProvider>
        <Global styles={GlobalStyles} />
        <Header altBg={altBg} hideNavItems={hideNavItems} />
        <ToastContainer />
        <MainContainer>
          {!hideBorders ? (
            <>
              <BorderLeft />
              <BorderRight />
            </>
          ) : null}
          <Main>{children}</Main>
          <Footer />
        </MainContainer>
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default Layout

