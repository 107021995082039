/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { FixedObject, FluidObject } from 'gatsby-image'

type SEOProps = {
  description?: string
  extraMetadata?: string
  image?: FluidObject | FixedObject
  keywords?: string[]
  lang?: string
  meta?: [{
    name: string
    content: string
  }]
  title?: string
};

function SEO({
  description,
  image,
  keywords = [],
  lang,
  meta = [],
  title,
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          content: metaDescription,
          name: `description`,
        },
        {
          content: title,
          property: `og:title`,
        },
        {
          content: metaDescription,
          property: `og:description`,
        },
        {
          content: `website`,
          property: `og:type`,
        },
        {
          content: `summary`,
          name: `twitter:card`,
        },
        {
          content: site.siteMetadata.author,
          name: `twitter:creator`,
        },
        {
          content: title,
          name: `twitter:title`,
        },
        {
          content: metaDescription,
          name: `twitter:description`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                content: keywords.join(`, `),
                name: `keywords`,
              }
            : [],
        )
        .concat(
          image ? [
            {
              content: `https:${image.src}`,
              property: `og:image`,
            },
            {
              content: `https:${image.src}`,
              name: `twitter:image`,
            },
          ] : []
        )
        .concat(meta)}
    />
  )
}

export default SEO
