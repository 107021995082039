import React, { createContext, useReducer, ReactNode } from 'react'
import getUTMParams from './utils/getUTMParams'
import {
  IAppState,
  IAppReducerAction,
  IAppContext,
} from './interfaces'

interface IProps {
  children: ReactNode
}

const initialState = {
  utmParams: typeof window !== 'undefined'
    ? getUTMParams(window.location.search)
    : {},
} as IAppState

const reducer = (state: IAppState, action: IAppReducerAction): IAppState => ({ ...state })

export const AppContext = createContext({} as IAppContext)

export function AppContextProvider(props: IProps) {
  const [appState, appDispatch] = useReducer(reducer, initialState)
  const value = { appState, appDispatch }

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
}

export const AppContextConsumer = AppContext.Consumer
