import React, { useContext, useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { Mq } from '../themes/default'
import { slide as MobileMenu } from 'react-burger-menu'

import Logo from '../atoms/logo'
import { CenteredRow, Row, Column } from '../atoms/grid'
import { NavLink, UnstyledGatsbyLink, HeaderButton } from '../atoms/link'
import {
  IContentfulTopNav,
  IContentfulLink,
} from '../interfaces'
import { AppContext } from '../app-context'
import MenuIcon from '../assets/images/menu-icon.svg'
import CrossIcon from '../assets/images/cross-icon.svg'

const HeaderEl = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  transition: background-color 0.2s ease-in-out;

  &.alt-bg {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &.sticky {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .button--cta {
    padding: 0.5rem 0.9rem;
    min-width: 140px;
    margin-left: 2rem;
    font-size: 0.9375rem;
  }
`

const HeaderContentContainer = styled(CenteredRow)`
  padding: 1rem 5%;

  ${Mq.md} {
    margin: 0 5%;
    padding: 1rem 3%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const ItemsContainer = styled(Column)`
  display: none;

  ${Mq.md} {
    display: inline-block;
  }
`

const CalloutContainer = styled(Row)`
  display: none;

  ${Mq.md} {
    display: flex;
  }
`

const MobileMenuContainer = styled.div`
  .bm-menu-wrap {
    a.active:before {
      top: -4px;
      left: -37px;
      height: 30px;
      width: 3px;
    }
    .bm-item .callout {
      color: ${props => props.theme.colors.white};
      margin-top: 3rem;
      display: block;
    }
  }

  ${Mq.md} {
    display: none;
  }
`

const mobileMenuStyles = {
  bmBurgerButton: {
    height: '1.2rem',
    position: 'fixed',
    right: '5%',
    top: '1.4rem',
    width: '2.3rem',
  },
  bmCrossButton: {
    width: '1.5rem',
    height: '1.5rem',
    right: '2rem',
    top: '1.4rem',
  },
  bmItem: {
    display: 'inline-block',
    margin: '1.5rem 0',
    marginLeft: '0',
  },
  bmItemList: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.8em',
    justifyContent: 'center',
  },
  bmMenu: {
    background: '#02314B',
    fontSize: '1em',
    padding: '3em 1.5em 3em',
  },
  bmMenuWrap: {
    height: '100%',
    position: 'fixed',
    width: '75%',
  },
  bmMorphShape: {
    fill: '#02314B',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

const renderNavItem = (navItem: IContentfulLink, callOut: boolean = false) => {
  return (
    <Column key={navItem.id} mx={callOut ? 0 : 24}>
      <NavLink link={navItem} callout={callOut} />
    </Column>
  )
}

if (typeof window !== `undefined`) {
  window.onscroll = () => {
    const headerEl = document.getElementById('header')
    const htmlEl = document.getElementsByTagName('html')[0]

    if (headerEl && htmlEl) {
      if (htmlEl.scrollTop >= 1) {
        headerEl.classList.add('sticky')
      } else {
        headerEl.classList.remove('sticky')
      }
    }
  }
}

const Header = props => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { appDispatch } = useContext(AppContext)
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        contentfulTopNav {
          items {
            ...Link
          }
          calloutItem {
            ...Link
          }
          ctaButtonText
        }
      }
    `,
  )
  const contentfulTopNav = data.contentfulTopNav as IContentfulTopNav

  contentfulTopNav.items = contentfulTopNav.items
    .map(item => {
      if (
        (process.env.GATSBY_BLOG_SECTION !== 'false' &&
          item.path === '/blog') ||
        (process.env.GATSBY_PRESS_SECTION !== 'false' &&
          item.path === '/press') ||
        (process.env.GATSBY_PEOPLE_SECTION !== 'false' &&
          item.path === '/people') ||
        item.path === '/faq'
      ) {
        return item
      }
    })
    .filter(Boolean)

  return (
    <>
      <HeaderEl id="header" className={props.altBg ? 'alt-bg' : ''}>
        <HeaderContentContainer py={3}>
          <Column>
            <UnstyledGatsbyLink aria-label="Go to home page" to="/">
              <Logo />
            </UnstyledGatsbyLink>
          </Column>
          { !props.hideNavItems ? (
            <>
              <ItemsContainer flex="1" alignSelf="center">
                <Row justifyContent="center">
                  {contentfulTopNav.items.map((navItem: IContentfulLink) => {
                    return renderNavItem(navItem)
                  })}
                </Row>
              </ItemsContainer>

              <CalloutContainer alignItems="center">
                <Column>
                  <HeaderButton
                    aria-label="Go to demo request page"
                    className="button--cta"
                    onClick={() => navigate('/request-demo')}
                  >
                    {contentfulTopNav.ctaButtonText}
                  </HeaderButton>
                </Column>
              </CalloutContainer>
           </>) : null }
        </HeaderContentContainer>
      </HeaderEl>

      <MobileMenuContainer>
        <MobileMenu
          isOpen={isMobileMenuOpen}
          styles={mobileMenuStyles}
          right={true}
          disableAutoFocus={true}
          customBurgerIcon={<img src={MenuIcon} alt="Open mobile menu" />}
          customCrossIcon={<img src={CrossIcon} alt="Close mobile menu" />}
        >
          {contentfulTopNav.items.map((navItem: IContentfulLink) => {
            return renderNavItem(navItem)
          })}

          <Column>
            <HeaderButton
              aria-label="Go to demo request page"
              className="button--cta"
              onClick={() => navigate('/request-demo')}
            >
              {contentfulTopNav.ctaButtonText}
            </HeaderButton>
          </Column>
        </MobileMenu>
      </MobileMenuContainer>
    </>
  )
}

export default Header
