import parseQueryString from './parseQueryString'

export default function getUTMParams(query) {
	try {
		const queryParams = parseQueryString(query)

		return Object.keys(queryParams).reduce((acc, key) => {
			if (
				key === 'utm_source'
				|| key === 'utm_medium'
				|| key === 'utm_campaign'
				|| key === 'utm_term'
			) {
				acc[key] = queryParams[key]
			}

			return acc
		}, {})
	} catch (e) {
		return {}
	}
}
