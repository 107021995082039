import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import SVG from 'react-inlinesvg'

import { Row, Column } from '../atoms/grid'
import { UnstyledLink, UnstyledGatsbyLink } from '../atoms/link'
import {
  IContentfulFooter,
  IContentfulLink,
  IContentfulSocialLink,
} from '../interfaces'
import { Mq } from '../themes/default'

const FooterEl = styled.footer`
  background-color: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.gray};
  width: 100%;
`

const FooterContentContainer = styled(Row)`
  padding: 1.45rem 0;
  margin: 0 5%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9375rem;

  ${Mq.md} {
    margin: 0 8%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const CopyrightDate = styled(Column)`
  font-weight: bold;
`

const SocialLink = styled(UnstyledLink)`
  color: rgba(255, 255, 255, 0.5);
`

const FooterLink = styled(UnstyledLink)`
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.primary};
  }
`

const FooterGatsbyLink = styled(UnstyledGatsbyLink)`
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.primary};
  }
`

const renderItem = (item: IContentfulLink) => {
  return (
    <Column key={item.id} ml={4}>
      {item.path ? (
        <FooterGatsbyLink to={item.path}>{item.title}</FooterGatsbyLink>
      ) : (
        <FooterLink
          href={item.externalLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.title}
        </FooterLink>
      )}
    </Column>
  )
}

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        contentfulFooter {
          phoneNumber
          items {
            ...Link
          }
          socialItems {
            icon {
              file {
                url
              }
            }
            link {
              ...Link
            }
          }
        }
      }
    `,
  )
  const contentfulFooter = data.contentfulFooter as IContentfulFooter

  return (
    <FooterEl>
      <FooterContentContainer alignItems="center">
        <CopyrightDate>
          © {new Date().getFullYear()} BiasSync
        </CopyrightDate>

        {process.env.GATSBY_PHONE_NUMBER_SECTION !== 'false' ? (
          <Column ml={4}>
            <FooterGatsbyLink href={`tel:${contentfulFooter.phoneNumber}`}>{contentfulFooter.phoneNumber}</FooterGatsbyLink>
          </Column>
        ) : null}

        {contentfulFooter.items.map((navItem: IContentfulLink) => {
          return renderItem(navItem)
        })}

        <Column flex={1} />

        {contentfulFooter.socialItems.map(
          (item: IContentfulSocialLink, index: number) => (
            <Column key={index} ml={4}>
              <SocialLink
                aria-label={item.link.title}
                href={item.link.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVG
                  width="24"
                  height="24"
                  src={item.icon.file.url}
                  className="social-icon"
                />
              </SocialLink>
            </Column>
          ),
        )}
      </FooterContentContainer>
    </FooterEl>
  )
}

export default Footer
