const Xs = '500px'
const Sm = '740px'
const Md = '900px'
const Lg = '1080px'
const Xl = '1200px'
const Xxl = '1540px'

// tslint:disable: object-literal-sort-keys
const Theme = {
  breakpoints: [Sm, Md, Lg, Xl, Xxl],
  colors: {
    primary: '#97d700',
    darkBlue: '#02314B',
    lightBlue: '#1AA0E9',
    lightGray: '#D8D8D8',
    lightBrown: '#F9F7F6',
    gray: '#FCF5EB',
    white: '#FFFFFF',
  },
  fonts: {
    primary: '"EB Garamond",serif',
    secondary: '"Poppins",sans-serif',
  },
  basicTransition: 'all 0.2s ease-in-out',
  fadeDistance: '70px',
}

const breakpoints = [
  { xs: Xs },
  { sm: Sm },
  { md: Md },
  { lg: Lg },
  { xl: Xl },
  { xxl: Xxl },
]

export const Mq = breakpoints.reduce((acc, breakpoint): any => {
  const entry = Object.entries(breakpoint)[0]
  acc = { ...acc, [entry[0]]: `@media (min-width: ${entry[1]})` }
  return acc
}, {})

export default Theme
