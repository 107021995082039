import React from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { Mq } from '../themes/default'
import Theme from '../themes/default'
import Fade from 'react-reveal/Fade'

import { Row, Column } from '../atoms/grid'
import { H1 } from '../atoms/heading'
import { Paragraph } from '../atoms/text'

const HeroBox = styled.div`
  position: absolute;
  z-index: -1;
  width: 75%;
  border: 10px solid ${props => rgba(props.theme.colors.lightGray, 0.5)};
  height: 33vh;
  margin: 0 auto;
  top: 12vh;
  left: 0;
  right: 0;

  ${Mq.md} {
    height: 30vh;
    top: 10vh;
    border-width: 1rem;
  }
  ${Mq.xxl} {
    width: 80%;
  }
`

const HeroParagraph = styled(Paragraph)`
  max-width: 55rem;
`

const HeroText = styled.div`
  margin-bottom: 2.5rem;

  ${Mq.md} {
    margin-bottom: 4rem;
  }
`

const HeroRow = styled(Row)`
  position: relative;
  height: 50vh;
  padding: 6rem 5% 0;
  align-items: center;

  ${Mq.md} {
    height: 45vh;
    margin: 5%;
    padding: 4rem 3% 0;
  }

  ${Mq.xxl} {
    margin: 4% 15% 0;
  }
`

export const SubpageHero = props => {
  return (
    <HeroRow px={50}>
      <Column>
        <HeroBox />

        <Fade bottom={true} distance={Theme.fadeDistance}>
          <HeroText>
            <H1>{props.title}</H1>
            <HeroParagraph largeText={true}>{props.text}</HeroParagraph>
          </HeroText>
        </Fade>
      </Column>
    </HeroRow>
  )
}
