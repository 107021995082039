export default function parseQueryString(query) {
	var decode = decodeURIComponent
	return (query.substring(1) + '')
		.replace(/\+/g, ' ')
		.split('&')
		.filter(Boolean)
		.reduce(function (obj, item, index) {
			var ref = item.split('=')
			var key = decode(ref[0] || '')
			var val = decode(ref[1] || '')
			var prev = obj[key]
			obj[key] = prev === undefined ? val : [].concat(prev, val)
			return obj
		}, {})
}
